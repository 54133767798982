import { getItem } from '@/utils/storage'

import CONFIG from '@/config'

const state = {
    // 登录用户，一个对象，包含 token 信息
    user: getItem(CONFIG.USER_KEY),
    // 过期时间
    expirationTime: getItem(CONFIG.SHANGTAI_EXPIRATION_TIME),
    cachedPages: ['Layout', 'Home'],
    // 公众号红包
    redPacket: getItem('SHANGTAI_RED_PACKET'),
    // 页面数据存储
    pageData: getItem(CONFIG.PAGE_DATA_KEY),
    // 微信用户信息
    wxInfo: getItem(CONFIG.WX_INFO_KEY),
    // 版本v2首页数据存储
    version2Index: getItem(CONFIG.SHANGTAI_VERSION2_INDEX),

    // v4版本
    v4User: getItem(CONFIG.V4_USER_KEY),
    // v4版本 验证劳动关系
    v4Labor: getItem(CONFIG.V4_LABOR_KEY),

    // v5版本
    v5User: getItem(CONFIG.V5_USER_KEY),


}

export default state