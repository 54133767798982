import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = 'SHANTAI@QUESTION@2024'; // 替换为自己的加密密钥

// 加密方法
export function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
}

// 解密方法
export function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}