
const actions = {

    // //TODO: 伪代码
    // login({ commit }, { username, password }) {

    //     return new Promise((resolve, reject) => {
    //         login({ username: 'admin', password: 'admin' }).then(res => {
    //             console.log(res)
    //             if (res.code === 200 && res.data.token) {
    //                 setToken(res.data.token)
    //                 resolve()
    //             } else {
    //                 reject(new Error('error'))
    //             }
    //         }).catch(err => {
    //             console.log(err)
    //             reject(err)
    //         })
    //     })
    // },

    // //TODO: 伪代码 配合loginSuper.js中的伪代码
    // authorization({ commit }, { token }) {
    //     return new Promise((resolve, reject) => {
    //         authorization().then(res => {
    //             if (parseInt(res.code) == 401) {
    //                 reject(new Error('token error'))
    //             }else{
    //                 //TODO: 每次请求都是返回最新的token过期时间
    //                 // 保证客户端体验，每次请求都是最新的token过期时间
    //                 setToken(res.data.token)
    //                 resolve()
    //             }
    //         }).catch(err => {
    //             console.log(err)
    //             reject(err)
    //         })
    //     })
    // },

    // 根据本地存储的过期时间，判断是否需要重新登录
    checkLogin({ state }) {
        const expirationTime = state.expirationTime
        // console.log('checkLogin', expirationTime)
        if (expirationTime && expirationTime > new Date().getTime()) {
            // 没有过期，还剩多长时间
            let time = expirationTime - new Date().getTime()
            // 格式化time,倒计时还有多少小时多少分钟
            let hours = Math.floor(time / (1000 * 60 * 60))
            let minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
            // console.log('剩余时间', hours, minutes)
       
            return true
        }
        // 过期了
        return false
    }



}

export default actions