import { setItem, removeItem } from '@/utils/storage'
import CONFIG from '@/config'

const mutations = {
    setUser(state, data) {
        // // 解析 JWT 中的数据（需要使用用户ID）
        // if (data && data.token) {
        //     const user = decodeJwt(data.token)
        //     data.user_id = user.user_id
        // }
        state.user = data
        // 为了防止刷新丢失 state 中的 user 状态，我们把它放到本地存储
        setItem(CONFIG.USER_KEY, state.user)
    },
    // 删除用户存储
    removeUser(state) {
        state.user = null
        removeItem(CONFIG.USER_KEY)
        removeItem(CONFIG.PAGE_DATA_KEY)
    },
    // 设置公众号红包信息
    setRedPacket(state, data) {
        // 先删除本地存储的红包信息
        removeItem('SHANGTAI_RED_PACKET')
        state.redPacket = data
        setItem('SHANGTAI_RED_PACKET', state.redPacket)
    },
    // 将页面数据存储到本地
    setPageData(state, data) {
        state.pageData = data
        setItem(CONFIG.PAGE_DATA_KEY, state.pageData)
    },
    // 将微信用户信息存储到本地
    setWxInfo(state, data) {
        state.wxInfo = data
        setItem(CONFIG.WX_INFO_KEY, state.wxInfo)
    },
    // 存储过期时间
    setExpirationTime(state, data) {
        state.expirationTime = data
        // 为了防止刷新丢失 state 中的 user 状态，我们把它放到本地存储
        setItem(CONFIG.SHANGTAI_EXPIRATION_TIME, state.expirationTime)
    },
    // 删除过期时间
    removeExpirationTime(state) {
        state.expirationTime = null
        removeItem(CONFIG.SHANGTAI_EXPIRATION_TIME)
    },
    // 存储版本v2首页数据
    setVersion2Index(state, data) {
        state.version2Index = data
        setItem(CONFIG.SHANGTAI_VERSION2_INDEX, state.version2Index)
    },

    // v4版本
    setV4User(state, data) {
        state.v4User = data
        setItem(CONFIG.V4_USER_KEY, state.v4User)
    },
    // v4版本劳动关系
    setV4Labor(state, data) {
        state.v4Labor = data
        setItem(CONFIG.V4_LABOR_KEY, state.v4Labor)
    },

    // v5版本
    setV5User(state, data) {
        state.v5User = data
        setItem(CONFIG.V5_USER_KEY, state.v5User)
    },

}

export default mutations