const routes = [
    // 仿法行宝 v5
    {
        path: '/v5',
        name: 'v5',
        component: () => import('../views/v5/layout'),
        redirect: '/v5/index',
        children: [
            {
                path: 'index',
                name: 'v5Index',
                component: () => import('../views/v5/index'),
                meta: { v5Auth: false, v5Labor: false }
            },
            {
                path: 'functionPage',
                name: 'v5functionPage',
                component: () => import('../views/v5/functionPage/index2.vue'),
                meta: { v5Auth: false, v5Labor: false }
            },
            // 问题提交
            {
                path: 'problems',
                name: 'v5problems',
                component: () => import('../views/v5/problems/index3.vue'),
                meta: { v5Auth: true, v5Labor: true }
            },
            // 意见书pdf
            {
                path: 'pdfView',
                name: 'v5pdfView',
                component: () => import('../views/v5/pdfView/index8.vue'),
                meta: { v5Auth: true }
            },
            // 劳动关系确认页
            {
                path: 'laborRelations',
                name: 'v5laborRelations',
                component: () => import('../views/v5/laborRelations/index9.vue'),
                meta: { v5Auth: true, v5Labor: false }
            },
            // 工资问题
            {
                path: 'wages',
                name: 'wages',
                component: () => import('../views/v5/wages/index3.vue'),
                meta: { v5Auth: true, v5Labor: false }
            },
            // demo页面，测试组件化
            {
                path: 'demo',
                name: 'v5demo',
                component: () => import('../views/v5/demo/index12.vue'),
                meta: { v5Auth: true, v5Labor: false }
            },
            // // Wdemo页面，测试组件化
            // {
            //     path: 'Wdemo',
            //     name: 'v5Wdemo',
            //     component: () => import('../views/v5/Wdemo/index3.vue'),
            //     meta: { v5Auth: false, v5Labor: false }
            // },
            // // Ldemo页面，测试组件化
            // {
            //     path: 'Ldemo',
            //     name: 'v5Ldemo',
            //     component: () => import('../views/v5/Ldemo/Ltest.vue'),
            //     meta: { v5Auth: false, v5Labor: false }
            // },
            // // Ldemo页面，测试组件化
            // {
            //     path: 'Lcsdemo',
            //     name: 'v5Lcsdemo',
            //     component: () => import('../views/v5/Ldemo/Ltest2.vue'),
            //     meta: { v5Auth: false, v5Labor: false }
            // },
        ]
    },

    {
        path: '/v4/t2t2',
        name: 'v4t2t2',
        component: () => import('../views/v4/testtest/t2t2002.vue'),
        meta: { requiresAuth: false }
    },


    // 404页面，跳转到index
    {
        path: '*',
        redirect: '/v5'
        // redirect: '/v3'
    }

]

export default routes