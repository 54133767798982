import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './router'

import store from '@/store'
import { Dialog } from 'vant'


Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //滚动行为
  scrollBehavior(to, from, savedPosition) {
    //返回的这个y=0，代表的滚动条在最上方
    return { y: 0 };
  },
})

router.beforeEach((to, from, next) => {

  // demo：/article/:articleId 模块需要登录
  if (to.meta.requiresAuth) {

    // 需要登录的模块，判断是否登录
    if (store.state.user) {
      // console.log('已登录', store.state.user)
      //TODO: 2024-03-26，增加crypto-js加密解密后台返回的数据
      return next()

    } else {
      // // 未登录，提示登录
      // Dialog.confirm({
      //   title: '该功能需要登录，确认登录吗？'
      // }).then(() => {
      //   router.replace({
      //     name: 'index',
      //     // query: {
      //     //   redirect: router.currentRoute.fullPath
      //     // }
      //   })
      // }).catch(() => {
      //   // 取消时，停止路由导航，停留在当前页面
      // })
      router.replace({ path: '/index' })
    }

  } else if (to.meta.v4Auth) {
    // v4模块需要登录
    console.log('v4Auth', to.meta.v4Auth)
    if (store.state.v4User) {
      return next()
    } else {
      router.replace({ path: '/v4' })
    }
  } else if (to.meta.v5Auth) {
    // v4模块需要登录
    console.log('v5Auth', to.meta.v5Auth)
    if (store.state.v5User) {
      return next()
    } else {
      router.replace({ path: '/v5' })
    }

  } else {
    // 不需要登录的模块，直接跳转
    next()
  }

})



export default router
